import Newsletter from '../newsletter'
import Hero from './hero'
import { HeroStyled, Title, Description, FormWrapper } from './hero-newsletter.styled'
import { node, object, string } from 'prop-types'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'

const HeroNewsletter = ({
	title,
	titleProps,
	description,
	descriptionProps,
	variant,
	action,
	inputProps,
	checkboxProps,
	buttonProps,
	iconButtonProps,
	...props
}) => {
	const {
		newsletter: { enabled: newsletterEnabled },
	} = useConfig()

	return newsletterEnabled ? (
		<HeroStyled
			overlayComponent={
				<>
					{title && (
						<Title variant="h3" {...titleProps}>
							{title}
						</Title>
					)}
					{description && (
						<Description component="div" variant="subheadline1" {...descriptionProps}>
							{description}
						</Description>
					)}
					<FormWrapper>
						<Newsletter
							variant={variant}
							action={action}
							checkboxProps={checkboxProps}
							inputProps={inputProps}
							buttonProps={buttonProps}
							iconButtonProps={iconButtonProps}
						/>
					</FormWrapper>
				</>
			}
			{...props}
		/>
	) : null
}

HeroNewsletter.defaultProps = {
	...Hero.defaultProps,
	verticalAlign: 'center',
	horizontalAlign: 'center',
	variant: 'standard',
	action: 'dialog',
	inputProps: {
		variant: 'filled',
		color: 'white',
	},
}

HeroNewsletter.propTypes = {
	...Hero.propTypes,
	title: node,
	titleProps: object,
	description: node,
	descriptionProps: object,
	action: string,
	inputProps: object,
	buttonProps: object,
	checkboxProps: object,
}

export default HeroNewsletter
