import MUIGrid from '@mui/material/Grid'
import { styled } from '@mui/system'
import SubmitButton from '../../molecules/button/submit-button'
import { ControlledInput } from '../../molecules/input'

const shouldForwardProp = (prop) => !['variant', 'type'].includes(prop)
const shouldForwardPropInput = (prop) => !['type'].includes(prop)
const shouldForwardPropButton = (prop) => !['inputlabels'].includes(prop)

const MUIGridStyled = styled(MUIGrid, { shouldForwardProp })`
	position: relative;
	align-items: flex-start;
	& .MuiFormControl-root {
		margin: 0;
		flex-direction: column;
		margin-right: ${({ variant }) => (variant === 'standard' ? '14px' : variant === 'compact' ? '0px' : null)};
	}
`

const ControlledInputStyled = styled(ControlledInput, { shouldForwardPropInput })`
	& .MuiInputBase-root {
		width: 100%;
		border-radius: 30px;
		${({ type }) => (type === 'compact' ? '& > input { padding-right: 76px }' : null)};
		> input {
			border-radius: 30px;
		}
	}
`

const SubmitIconButtonStyled = styled(SubmitButton, { shouldForwardPropButton })`
	position: absolute;
	top: 0;
	right: 0;
	width: 60px;
	height: 60px;
	border-radius: 0 30px 30px 0;
	${({ inputlabels }) => (inputlabels !== '' ? 'margin-top: 23px' : null)};
	color: ${({ theme }) => theme.palette.common.white};
	background-color: ${({ theme }) => theme.palette.footer.bgBottom};
`

const Wrapper = styled('div')`
	& .MuiFormControlLabel-root {
		margin: 0;
	}
`

const SubmitButtonStyled = styled(SubmitButton, { shouldForwardPropButton })`
	${({ inputlabels }) => (inputlabels !== '' ? 'margin-top: 22px' : null)};
	position: absolute;
	top: 0;
	right: 0;
	color: ${({ theme }) => theme.palette.common.white};
	background-color: ${({ theme }) => theme.palette.footer.bgBottom};
	border-radius: 0 30px 30px 0;
`

export { MUIGridStyled, ControlledInputStyled, SubmitIconButtonStyled, Wrapper, SubmitButtonStyled }
