/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const CheckboxOffIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M3 1.5A1.5 1.5 0 001.5 3v18A1.5 1.5 0 003 22.5h18a1.5 1.5 0 001.5-1.5V3A1.5 1.5 0 0021 1.5H3M3 0h18a3 3 0 013 3v18a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3z" />
	</svg>
))
CheckboxOffIconSvg.displayName = 'CheckboxOffIconSvg'

const CheckboxOffIcon = forwardRef((props, ref) => <Icon component={CheckboxOffIconSvg} ref={ref} {...props} />)
CheckboxOffIcon.displayName = 'CheckboxOffIcon'

CheckboxOffIcon.defaultProps = {
	...Icon.defaultProps,
}
CheckboxOffIcon.propTypes = {
	...Icon.propTypes,
}

export default CheckboxOffIcon
export { CheckboxOffIconSvg }
