import Typography from '../../atoms/typography'
import { styled } from '@mui/system'
import Hero from './hero'

const HeroStyled = styled(Hero)`
	.overlay-container {
		${({ theme }) => theme.breakpoints.up('md')} {
			width: 670px;
		}
	}
`

const Title = styled(Typography)`
	line-height: 1.1;
`
const Description = styled(Typography)`
	margin: 20px auto;
	${({ theme }) => theme.breakpoints.up('md')} {
		max-width: 450px;
	}
`
const FormWrapper = styled('div')`
	margin-top: 12px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: nowrap;
	flex-direction: column;
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
	}
`

export { Title, Description, FormWrapper, HeroStyled }
