/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const CheckboxOnIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<defs>
			<clipPath id="prefix__a">
				<path d="M0 0h24v24H0z" />
			</clipPath>
		</defs>
		<g clipPath="url(#prefix__a)">
			<path fill="currentColor" d="M0 0h24v24H0z" />
			<path d="M20 23H4a3 3 0 01-3-3V4a3 3 0 013-3h16a3 3 0 013 3v16a3 3 0 01-3 3zM5.956 10.644a1.07 1.07 0 00-.756 1.829L9.5 16.8a1.068 1.068 0 001.515 0l8.3-8.082A1.072 1.072 0 1017.8 7.2l-7.541 7.323-3.546-3.564a1.065 1.065 0 00-.757-.315z" />
		</g>
	</svg>
))
CheckboxOnIconSvg.displayName = 'CheckboxOnIconSvg'

const CheckboxOnIcon = forwardRef((props, ref) => <Icon component={CheckboxOnIconSvg} ref={ref} {...props} />)
CheckboxOnIcon.displayName = 'CheckboxOnIcon'

CheckboxOnIcon.defaultProps = {
	...Icon.defaultProps,
}
CheckboxOnIcon.propTypes = {
	...Icon.propTypes,
}

export default CheckboxOnIcon
export { CheckboxOnIconSvg }
